<template>
  <div class="w-full">
    <!--Nav-->
    
    <NavBar />
    <div class="container mx-auto h-screen">
      <div class="text-center px-3 lg:px-0">
        <h1
          class="my-4 text-2xl md:text-3xl lg:text-5xl font-black leading-tight"
        >
          Your own personal adops assistant.
        </h1>
        <p
          class="leading-normal text-gray-800 text-base md:text-xl lg:text-2xl mb-8"
        >
          With the click of a button, automate all your ad screenshots.
        </p>

        <t-button 
          
          classes="mx-auto lg:hover:underline text-gray-800 font-extrabold rounded my-2 md:py-4 px-8 shadow-lg w-48"
          to="/demo"
        >
          Try It
        </t-button>
        <!-- <a
          href="#"
          class="inline-block mx-auto lg:mx-0 hover:underline bg-transparent text-gray-600 font-extrabold my-2 md:my-6 py-2 lg:py-4 px-8"
        >View Additional Action</a> -->
      </div>

      <div class="flex items-center w-full mx-auto content-end">
        <div
          class="browser-mockup flex flex-1 m-6 md:px-0 md:m-12 bg-white w-1/2 rounded shadow-xl"
        />
      </div>
    </div>

    <HomeSections v-if="false" />
    <!--Footer-->
    <footer class="bg-white ">
      <div class="container mx-auto mt-8 px-8">
        <div class="w-full flex flex-col justify-between md:flex-row py-6">
          <div class="mb-6">
            <a
              class="text-orange-600 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
              href="#"
            >
              <img
                class="h-48"
                src="/logo.png"
              >
            </a>
          </div>

          <!-- <div class="flex-1">
            <p class="uppercase font-extrabold text-gray-500 md:mb-6">
              Links
            </p>
            <ul class="list-reset mb-6">
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="#"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500"
                >FAQ</a>
              </li>
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="#"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500"
                >Help</a>
              </li>
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="#"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500"
                >Support</a>
              </li>
            </ul>
          </div>
          <div class="flex-1">
            <p class="uppercase font-extrabold text-gray-500 md:mb-6">
              Legal
            </p>
            <ul class="list-reset mb-6">
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="#"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500"
                >Terms</a>
              </li>
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="#"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500"
                >Privacy</a>
              </li>
            </ul>
          </div>
          <div class="flex-1">
            <p class="uppercase font-extrabold text-gray-500 md:mb-6">
              Social
            </p>
            <ul class="list-reset mb-6">
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="#"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500"
                >Facebook</a>
              </li>
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="#"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500"
                >Linkedin</a>
              </li>
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="#"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500"
                >Twitter</a>
              </li>
            </ul>
          </div> -->
          <div class="pr-4">
            <p class="uppercase font-extrabold text-gray-500 md:mb-6">
              Company
            </p>
            <ul class="list-reset mb-6">
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="#"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500"
                >Official Blog</a>
              </li>
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="#"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500"
                >About Us</a>
              </li>
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="#"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500"
                >Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>

import NavBar from '@/components/NavBar';
import HomeSections from '@/components/HomeSections';
export default {
  name: 'Home',
    components: {
    NavBar,
    HomeSections,
  },
  props: {
  }
}
</script>