import Vue from 'vue';
import App from './App.vue';
import router from "./router";
import './index.css';
import {
  FirebaseApp,
  FirebaseAuth,
  Firestore,
  FirebaseFunctions
} from "@/core/firebase";

import VueTailwind from 'vue-tailwind';
import { settings } from './tailwind'

Vue.use(VueTailwind, settings);

Vue.config.productionTip = false;
Vue.prototype.$auth = FirebaseAuth;
Vue.prototype.$firestore = Firestore;
Vue.prototype.$firebase = FirebaseApp;
Vue.prototype.$functions = FirebaseFunctions;


FirebaseAuth.onAuthStateChanged(() => {
  new Vue({
    router,
    render: (h) => h(App),
  }).$mount('#app');
})

