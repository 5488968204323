<template>
  <div class="vld-parent">
    <loading
      :active.sync="loading"
      :is-full-page="fullPage"
    />
    <div class="max-w-full h-full mx-auto bg-white py-8 sm:px-6 lg:px-8">
      <!-- xs to lg -->
      <div
        class="max-w-full mx-auto lg:hidden"
        v-if="loading"
      >
        <div
          class="px-4"
          v-for="(product, index) in products"
          :key="product.id"
        >
          <h2 class="text-lg leading-6 font-medium text-gray-900" />
          <p class="mt-4">
            <span class="text-4xl font-extrabold text-gray-900" />
            <span class="text-base font-medium text-gray-500" />
          </p>
          <a
            href="#"
            class="mt-6 block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
          />
          <a
            href="#"
            class="mt-6 block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
            v-if="!role"
            @click="checkoutSession(index)"
          >Buy {{ product.name }}</a>
        </div>
      </div>
      <div
        class="max-w-full mx-auto lg:hidden"
        v-if="!loading"
      >
        <div
          class="px-4"
          v-for="(product, index) in products"
          :key="product.id"
        >
          <h2 class="text-lg leading-6 font-medium text-gray-900">
            {{ product.name }}
          </h2>
          <p class="mt-4">
            <span class="text-4xl font-extrabold text-gray-900">{{
              `${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: product.prices[0].currency,
              }).format((product.prices[0].unit_amount / 100).toFixed(2))}`
            }}</span>
            <span class="text-base font-medium text-gray-500">/mo</span>
          </p>
          <p class="mt-4 text-sm text-gray-500">
            Quis suspendisse ut fermentum neque vivamus non tellus.
          </p>
          <a
            class="mt-6 block border border-gray-700 rounded-md bg-gray-700 w-full py-2 text-sm font-semibold text-white text-center"
            v-if="product.role == role"
            @click="billingPortalHandler(index)"
          >You are already {{ product.name }}!</a>
          <a
            class="mt-6 block border border-gray-400 rounded-md bg-gray-400 w-full py-2 text-sm font-semibold text-white text-center"
            v-else-if="isTrialing"
            @click="billingPortalHandler(index)"
          >Update to {{ product.name }}</a>
          <a
            href="#"
            class="mt-6 block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
            v-else-if="role"
            @click="billingPortalHandler(index)"
          >Update to {{ product.name }}</a>
          <a
            href="#"
            class="mt-6 block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
            v-else-if="!role"
            @click="checkoutSession(index)"
          >Buy {{ product.name }}</a>
        </div>
      </div>
      <!-- lg+ -->
      <div class="hidden lg:block">
        <table
          class="w-full h-px table-fixed"
          v-if="!loading"
        >
          <caption class="sr-only">
            Pricing plan comparison
          </caption>
          <thead>
            <tr>
              <th
                class="pb-4 px-6 text-sm font-medium text-gray-900 text-left"
                scope="col"
              >
                <span class="sr-only">Feature by</span>
                <span>Plans</span>
              </th>
              <th
                class="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
                scope="col"
                v-for="product in products"
                :key="product.id"
              >
                {{ product.name }}
              </th>
            </tr>
          </thead>
          <tbody class="border-t border-gray-200 divide-y divide-gray-200">
            <tr>
              <th
                class="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top"
                scope="row"
              >
                Pricing
              </th>
              <td
                class="h-full py-8 px-6 align-top"
                v-for="(product, index) in products"
                :key="product.id"
              >
                <div class="relative h-full table">
                  <p>
                    <span class="text-4xl font-extrabold text-gray-900">{{
                      `${new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: product.prices[0].currency,
                      }).format(
                        (product.prices[0].unit_amount / 100).toFixed(2)
                      )}`
                    }}</span>
                    <span class="text-base font-medium text-gray-500">/mo</span>
                  </p>
                  <p class="mt-4 mb-16 text-sm text-gray-500">
                    Quis suspendisse ut fermentum neque vivamus non tellus.
                  </p>
                  <a
                    href="#"
                    class="absolute bottom-0 flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                    v-if="!role"
                    @click="checkoutSession(index)"
                  >Buy {{ product.name }}</a>
                  <a
                    href="#"
                    class="absolute bottom-0 flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                    v-if="role"
                    @click="billingPortalHandler()"
                  >Update to {{ product.name }}</a>
                  <p
                    class="absolute bottom-0 flex-grow block w-full bg-gray-700 border border-gray-700 rounded-md 5 py-2 text-sm font-semibold text-white text-center"
                    v-if="product.role == role"
                    @click="billingPortalHandler()"
                  >
                    You are already {{ product.name }}!
                  </p>
                  <a
                    class="absolute bottom-0 flex-grow block w-full bg-gray-400 border border-gray-400 rounded-md 5 py-2 text-sm font-semibold text-white text-center"
                    v-else-if="isTrialing"
                  >Update to {{ product.name }}</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import {
  fetchCustomer,
  fetchProducts,
  getCustomClaimRole,
} from "../services/functions";
import "vue-loading-overlay/dist/vue-loading.css";
const STRIPE_PUBLISHABLE_KEY = "";//TODO: Stripe publishable key

export default {
  name: "DashboardPricing",
  components: { Loading },
  async created() {
    this.role = await getCustomClaimRole(this.$auth.currentUser);
    this.products = await fetchProducts(this.$firestore);
    const customer = await fetchCustomer(
      this.$firestore,
      this.$auth.currentUser
    );
    this.isTrialing = customer.isTrialing;
    this.customer = customer.customerId;
    this.isLoading();
  },
  data() {
    return {
      role: null,
      products: [],
      loading: true,
      fullPage: true,
      customer: null,
      isTrialing: false,
    };
  },
  methods: {
    isLoading() {
      this.loading = true;
      // simulate AJAX
      setTimeout(() => {
        this.loading = !this.loading;
      }, 700);
    },
    async checkoutSession(index) {
      this.loading = !this.loading;
      const currentUser = this.$auth.currentUser.uid;
      const db = this.$firestore.collection("customers");
      const docRef = await db
        .doc(currentUser)
        .collection("checkout_sessions")
        .add({
          customer: this.customer,
          success_url: window.location.origin,
          cancel_url: window.location.origin,
          line_items: [
            {
              price: this.products[index].prices[0].id,
              quantity: 1,
            },
          ],
          payment_method_types: ["card"],
        });
      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot((snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          // Show an error to your customer and then inspect your function logs.
          alert(`An error occured: ${error.message}`);
          this.loading = !this.loading;
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          //eslint-disable-next-line
          const stripe = Stripe(STRIPE_PUBLISHABLE_KEY);
          stripe.redirectToCheckout({ sessionId: sessionId }).then((result) => {
            // If `redirectToCheckout` fails due to a browser or network
            // error, you should display the localized error message to your
            // customer using `error.message`.
            if (result.error) {
              alert(result.error.message);
              this.loading = !this.loading;
            }
          });
        }
      });
    },
    /**
     * If customer has an active subscription he's taken to the
     * billing portal instead.
     */
    async billingPortalHandler() {
      this.loading = true;
      const functionRef = this.$functions.httpsCallable(
        "ext-firestore-stripe-subscriptions-createPortalLink"
      );
      const { data } = await functionRef({ returnUrl: window.location.origin });
      window.location.assign(data.url);
    },
  },
};
</script>
