<template>
  <section class="h-screen w-full bg-indigo-600">
    <div
      class="mx-auto flex justify-center lg:items-center h-full"
      active
    >
      <!-- Login -->
      <form
        id="login"
        class="w-full sm:w-4/6 md:w-3/6 lg:w-4/12 xl:w-3/12 text-white py-12 px-2 sm:px-0 font-extrabold"
        @submit.prevent
      >
        <div class="pt-8 px-2 flex flex-col items-center justify-center">
          <h3
            class="text-2xl sm:text-3xl xl:text-2xl font-bold leading-tight"
          >
            Login
          </h3>
        </div>
        <div class="mt-12 w-full px-2 sm:px-6">
          <div class="flex flex-col mt-5">
            <label
              for="email"
              class="text-lg font-semibold leading-tight"
            >Email</label>
            <input
              required
              id="email"
              name="email"
              class="h-10 px-2 w-full bg-white text-black rounded mt-2 focus:outline-none shadow"
              type="email"
            >
          </div>
          <div class="flex flex-col mt-5">
            <label
              for="password"
              class="text-lg font-semibold fleading-tight"
            >Password</label>
            <input
              required
              id="password"
              name="password"
              class="h-10 px-2 w-full text-black bg-white rounded mt-2 focus:outline-none shadow"
              type="password"
            >
          </div>
        </div>
        <div class="px-2 sm:px-6">
          <button
            class="focus:outline-none w-full bg-white transition duration-150 ease-in-out hover:bg-gray-200 rounded text-gray-800 font-extrabold px-8 py-3 text-sm mt-6"
          >
            Login
          </button>
          <p class="mt-16 text-xs text-center">
            Don’t Have An Account?
            <router-link to="/register">
              <a class="underline">Sign Up</a>
            </router-link>
          </p>
        </div>
      </form>
      <!-- Login ends -->
    </div>
  </section>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      auth: false,
    };
  },
  mounted: function () {
    this.submit();
  },
  methods: {
    submit() {
      let login = document.getElementById("login");
      login.addEventListener(
        "submit",
        (event) => {
          event.preventDefault();
          const email = login.email.value;
          const password = login.password.value;
          this.$firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
              this.auth = true;
              if (this.auth) {
                this.$router.push("/dashboard/home").catch((err) => err);
              }
            })
            .catch((error) => {
              alert(error);
            });
        },
        this.auth ? true : false
      );
    },
  },
};
</script>