<template>
  <router-view />
  <!-- <div class="w-full">
     <img
      width="25%"
      src="./assets/logo.png"
    >
    <button
      type="button"
      @click="onClick"
    >
      get pdf
    </button>
    <a
      ref="link"
      :href="url"
      download="file.pdf"
    />
    <t-alert
      variant="success"
      show
    >
      Good work, everything is working as expected!
    </t-alert>
    <img
      v-if="imageUrl!=''"
      :src="imageUrl"
    > -->
    
  <!-- <HelloWorld v-if="false" />
  <Home /> -->
  <!-- </div> -->
</template>

<script>
// import HelloWorld from '@/components/HelloWorld';
// import Home from '@/views/home';
import axios from "axios";

export default {
  name: "App",
  components: {
    // Home,
    // HelloWorld,
  },
  data() {
    return {
      url: "",
      imageUrl: '',
    };
  },

  methods: {
    bufferToImageUrl(buffer) {
      var arrayBufferView = new Uint8Array( buffer );
      var blob = new Blob( [ arrayBufferView ], { type: "image/jpeg" } );
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL( blob );
      return imageUrl;
  },
    async onClick() {
      const pageToScreenshot='http://example.com/';
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({ pageToScreenshot: pageToScreenshot })
    };
      await axios.post("/.netlify/functions/index", options)
        .then((res) => {
            console.log(res)
            console.log(this.bufferToImageUrl(res.data.data))
            this.imageUrl = res.data.data
        })
        .catch((err) => {
            console.log(err)
        });
    }
  }
};
</script>

<style>
* {
  box-sizing: border-box;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
