<!-- Main view -->
<template>
  <div class="w-full h-screen bg-gray-300">
    <DashboardSidebar class="fixed z-20 h-screen" />
    <div class="w-full">
      <DashboardNavbar />
      <!-- Remove class [ h-64 ] when adding a card block -->
      <div class="h-full container mx-auto py-10 w-11/12 lg:pl-40">
        <keep-alive>
          <router-view />
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "../components/DashboardNavbar";
import DashboardSidebar from "../components/DashboardSidebar";

export default {
  name: "Dashboard",
  components: { DashboardSidebar, DashboardNavbar },
};
</script>
<style scoped>
body {
  font-family: "Lato", sans-serif;
}
</style>