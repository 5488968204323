<template>
  <div class="vld-parent w-full">
    <loading
      :active.sync="loading"
      :is-full-page="fullPage"
    />
    <section class="h-screen bg-indigo-600">
      <div
        class="mx-auto flex justify-center lg:items-center h-full"
        active
      >
        <!-- Login -->
        <form
          id="register"
          class="w-full sm:w-4/6 md:w-3/6 lg:w-4/12 xl:w-3/12 py-12 px-2 text-white sm:px-0"
          @submit.prevent
        >
          <div class="pt-8 px-2 flex flex-col items-center justify-center">
            <h3
              class="text-2xl sm:text-3xl xl:text-2xl font-bold leading-tight"
            >
              Create an Account
            </h3>
          </div>
          <div class="mt-12 w-full px-2 sm:px-6">
            <div class="flex flex-col mt-5">
              <label
                for="email"
                class="text-lg font-semibold leading-tight"
              >Email</label>
              <input
                required
                id="loginEmail"
                name="email"
                class="h-10 px-2 w-full text-black bg-white rounded mt-2 focus:outline-none shadow"
                type="email"
              >
            </div>
            <div class="flex flex-col mt-5">
              <label
                for="password"
                class="text-lg font-semibold fleading-tight"
              >Password</label>
              <input
                required
                id="loginPassword"
                name="password"
                class="h-10 px-2 w-full text-black bg-white rounded mt-2 focus:outline-none shadow"
                type="password"
              >
            </div>
          </div>
          <div class="px-2 sm:px-6">
            <button
              class="focus:outline-none w-full bg-white transition duration-150 ease-in-out hover:bg-gray-200 rounded text-gray-800 font-extrabold px-8 py-3 text-sm mt-6"
            >
              Sign Up
            </button>
            <p class="mt-16 text-xs text-center font-extrabold">
              Have An Account?
              <router-link to="Login">
                <a class="underline"> Log in </a>
              </router-link>
            </p>
          </div>
        </form>
        <!-- Login ends -->
      </div>
    </section>
  </div>
</template>

<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Login",
  components: { Loading },
  data() {
    return {
      loading: false,
      fullPage: true,
    };
  },
  mounted: function () {
    this.submit();
  },
  methods: {
    submit() {
      let register = document.getElementById("register");
      register.addEventListener(
        "submit",
        (event) => {
          this.loading = !this.loading;
          event.preventDefault();
          const email = register.email.value;
          const password = register.password.value;
          this.$firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(() => {
              this.$firebase.auth().onIdTokenChanged((user) => {
                if (user) {
                  this.$router.replace("/dashboard/home").catch((err) => err);
                } else {
                  this.$router.replace("/login").catch((err) => err);
                }
              });
            })
            .catch((error) => {
              alert(error);
              this.loading = !this.loading;
            });
        },
        true
      );
    },
  },
};
</script>