<template>
  <!-- Navigation starts -->
  <nav
    class="h-16 flex items-center bg-white lg:items-stretch justify-end lg:justify-between shadow relative z-10"
  >
    <div class="hidden lg:flex w-full pr-6">
      <div class="w-1/2 h-full hidden lg:flex items-center pl-6 pr-24" />
      <div class="w-1/2 hidden lg:flex">
        <div class="w-full flex items-center pl-8 justify-end">
          <div
            class="flex items-center relative cursor-pointer"
            @click="dropdownHandler($event)"
          >
            <div class="rounded-full">
              <ul
                class="p-2 w-full border-r bg-white absolute rounded left-0 shadow mt-12 sm:mt-16 hidden"
              >
                <li
                  class="flex w-full justify-between text-gray-600 hover:text-indigo-700 cursor-pointer items-center mt-2"
                  @click="signOut"
                >
                  <div class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-logout"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path
                        stroke="none"
                        d="M0 0h24v24H0z"
                      />
                      <path
                        d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"
                      />
                      <path d="M7 12h14l-3 -3m0 6l3 -3" />
                    </svg>
                    <span class="text-sm ml-2">Sign out</span>
                  </div>
                </li>
              </ul>
              <div class="relative">
                <img
                  class="h-10 w-10 object-cover"
                  src="https://upload.wikimedia.org/wikipedia/commons/9/98/OOjs_UI_icon_userAvatar.svg"
                  alt="avatar"
                >
                <div
                  class="w-2 h-2 rounded-full bg-green-400 border border-white absolute inset-0 mb-0 mr-0 m-auto"
                />
              </div>
            </div>
            <p class="text-gray-800 text-sm mx-3">
              {{ email }}
            </p>
            <div class="cursor-pointer text-gray-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="chevron hidden icon icon-tabler icon-tabler-chevron-up"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  stroke="none"
                  d="M0 0h24v24H0z"
                />
                <polyline points="6 15 12 9 18 15" />
              </svg>
              <svg
                aria-haspopup="true"
                xmlns="http://www.w3.org/2000/svg"
                class="chevron icon icon-tabler icon-tabler-chevron-down"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  stroke="none"
                  d="M0 0h24v24H0z"
                />
                <polyline points="6 9 12 15 18 9" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="text-gray-600 mr-8 visible lg:hidden relative"
      @click="responsiveDropdownHandler($event)"
    >
      <ul
        class="p-2 w-40 border-r bg-white absolute rounded right-0 shadow mt-12 hidden"
      >
        <li
          class="flex w-full justify-between text-gray-600 hover:text-indigo-700 cursor-pointer items-center mt-2"
          @click="signOut"
        >
          <div class="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-logout"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                stroke="none"
                d="M0 0h24v24H0z"
              />
              <path
                d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"
              />
              <path d="M7 12h14l-3 -3m0 6l3 -3" />
            </svg>
            <span class="text-sm ml-2">Sign out</span>
          </div>
        </li>
      </ul>
      <svg
        aria-label="Main Menu"
        aria-haspopup="true"
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-menu cursor-pointer"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path
          stroke="none"
          d="M0 0h24v24H0z"
        />
        <line
          x1="4"
          y1="8"
          x2="20"
          y2="8"
        />
        <line
          x1="4"
          y1="16"
          x2="20"
          y2="16"
        />
      </svg>
    </div>
  </nav>
  <!-- Navigation ends -->
</template>

<script>
export default {
  name: "DashboardNavbar",
  components: {},
  created() {
    this.fetchUser();
  },
  data() {
    return {
      email: "",
      moved: true,
    };
  },
  methods: {
    fetchUser() {
      this.email = this.$auth.currentUser.email;
    },
    dropdownHandler(event) {
      let single = event.currentTarget.getElementsByTagName("ul")[0];
      single.classList.toggle("hidden");

      var andicator = event.currentTarget.querySelectorAll("svg");
      if (!single.classList.contains("hidden")) {
        andicator[1].style.display = "block";
        andicator[2].style.display = "none";
      } else {
        andicator[1].style.display = "none";
        andicator[2].style.display = "block";
      }
    },
    responsiveDropdownHandler(event) {
      let single = event.currentTarget.getElementsByTagName("ul")[0];
      single.classList.toggle("hidden");
    },
    sidebarHandler() {
      var sideBar = document.getElementById("mobile-nav");
      sideBar.transform = "translateX(-260px)";
      if (this.$data.moved) {
        sideBar.transform = "translateX(0px)";
        this.$data.moved = false;
      } else {
        sideBar.transform = "translateX(-260px)";
        this.$data.moved = true;
      }
    },
    signOut() {
      this.$firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("/");
        });
    },
  },
};
</script>