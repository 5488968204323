export const fetchCustomer = async function (firestore, currentUser) {
    // firestore collection of customers
    const db = firestore.collection("customers");
    const customer = (await db.doc(currentUser.uid).get()).data();
    /**
     * First,
     * let's check if there's records of the user:
     */
    if ((customer === undefined) || (customer == "undefined")) {
        /**
         * If there are some delays with cloud functions and the user
         * recods hasn't been stored, we may return the initial zero data. 
         */
        return {
            customerCredits: 0,
            customerCreditsUsed: 0,
            subscriptionPeriodEnd: null,
            isTrialing: false,
            customerId: null
        };
    } else {
        /**
         * Otherwise, we fetch the user:
         */
        const customerCredits = customer.credits;
        const customerCreditsUsed = customer.creditsUsed;
        const customerId = customer.stripeId;
        // Then check if has a subscription later on in any return from stripe checkout portal.
        const subscriptionSnapshot = await db.doc(currentUser.uid).collection("subscriptions").get();
        var hasSubscription;
        var isTrialing;
        var subscriptionPeriodEnd;
        subscriptionSnapshot.forEach((doc) => {
            const data = doc.data();
            /**
             * If has a subscription record we can now his status and
             * period end.
             */
            if (!data.empty) {
                hasSubscription = true;
                if (data.status === "trialing") {
                    const date = new Date(data.trial_end?.seconds * 1000);
                    subscriptionPeriodEnd = date.toDateString()
                    isTrialing = true;
                }
                if (data.status === "active") {
                    const date = new Date(data.current_period_end?.seconds * 1000);
                    subscriptionPeriodEnd = date.toDateString();
                    isTrialing = false;
                }
            } else {
                hasSubscription = false;
            }
        });
        return { customerCredits, customerId, customerCreditsUsed, isTrialing, hasSubscription, subscriptionPeriodEnd };
    }
}

export const getCustomClaimRole = async function (auth) {
    /**
     * Get the customer role as in the stripe sample.
     * Custom roles has to be added as firebaseRole in the product metadata.
     */
    await auth.getIdToken(true);
    const decodedToken = await auth.getIdTokenResult();
    return decodedToken.claims.stripeRole;
}

export const fetchProducts = function (firestore) {
    // Database reference
    const db = firestore.collection("products");
    // Init an empty array of products.
    var products = [];
    // Fetch every active product.
    db.where("active", "==", true)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
                /**
                 * For free plan it avoids to add it to the products array.
                 * You may have change or devare the if conditional based on
                 * whether you have or not a free plan.
                 */
                if (doc.data().name != "Free") {
                    /**
                     * var's fetch prices collection. Since stripe allows more than one price
                     * for a product, it is convenient to have them stored in an array.
                     */
                    let priceSnap = await doc.ref
                        .collection("prices")
                        .where("active", "==", true)
                        .orderBy("unit_amount")
                        .get();
                    // Array of prices.
                    let prices = [];
                    // Iterates over every doc in prices collection
                    priceSnap.forEach((snap) => {
                        let price = snap.data();
                        // Add the price id to the object.
                        Object.defineProperty(price, 'id', { value: snap.id });
                        // Then push your price object into prices array.
                        prices.push(price);
                    });
                    // var's create the product object now.
                    let product = doc.data();
                    // Add the id.
                    Object.defineProperty(product, 'id', { value: doc.id });
                    // And the array of prices as well.
                    Object.defineProperty(product, 'prices', { value: prices });
                    // Then push all your products.
                    products.push(product);
                }
            });
        });
    return products;
}

export const fetchInvoices = async function (firestore, currentUser) {
    // Subscriptions collection from customer.
    const db = firestore
        .collection("customers")
        .doc(currentUser)
        .collection("subscriptions");
    // New array of invoices from the customer.
    var invoices = new Array();

    db.get().then(async (subscriptionSnapshot) => {
        /**
         * If the customer has a subscrption stored then
         * invoices collection exists and we can fetch data from there.
         */
        if (!subscriptionSnapshot.empty) {
            // Iterate over every single subscription has have.
            subscriptionSnapshot.forEach((doc) => {
                // Look up for invoices collection.
                doc.ref.collection("invoices").get().then((invoiceSnapshot) => {
                    /**
                     * Then iterates over every single invoice document since what
                     * we want to do do is show all the invoices as full transactions
                     * showcase.
                     */
                    invoiceSnapshot.forEach(async (doc) => {
                        /**
                         * Data to be fetched is completely arbitrary. 
                         */
                        let invoice = {
                            "billing_reason": doc.data().billing_reason,
                            "created": doc.data().created,
                            "currency": doc.data().currency,
                            "hosted_invoice_url": doc.data().hosted_invoice_url,
                            "id": doc.id,
                            "invoice_pdf": doc.data().invoice_pdf,
                            "number": doc.data().number,
                            "period_end": doc.data().period_end,
                            "period_start": doc.data().period_start,
                            "status": doc.data().status,
                            "subtotal": doc.data().subtotal,
                            "total": doc.data().total
                        };
                        // Add the document id inside invoice id.
                        Object.defineProperty(invoice, 'invoice_id', { value: doc.id });
                        // Then push the object inside the array of invoices [{Objects}].
                        invoices.push(invoice);
                    });
                })
            });
        }
    });
    return invoices;
}