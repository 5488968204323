//import * as Firebase from 'firebase/app';
import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {  
  apiKey: "AIzaSyCtm5fJ2yb_AMHLQlvP_q8JjmX2lbtQcgk",
  authDomain: "adsnaps-2930c.firebaseapp.com",
  projectId: "adsnaps-2930c",
  storageBucket: "adsnaps-2930c.appspot.com",
  messagingSenderId: "778687925360",
  appId: "1:778687925360:web:99fde65d87d03e0c83f8b5",
};

// !Firebase.apps.length ? Firebase.initializeApp(firebaseConfig) : '';

export const FirebaseApp = firebase.initializeApp(firebaseConfig);
export const FirebaseAuth = FirebaseApp.auth();
export const Firestore = FirebaseApp.firestore();
export const FirebaseStorage = FirebaseApp.storage();
export const FirebaseFunctions = FirebaseApp.functions();
//export const GoogleAuthProvider = new FirebaseApp.auth.GoogleAuthProvider();
/* if (window.location.hostname === 'localhost') {
  FirebaseApp.firestore().useEmulator('localhost', 8080);
  FirebaseApp.functions().useEmulator('localhost', 5001);
  /* OLD implementation */
  // Firebase.firestore().settings({ host: 'localhost:8080', ssl: false });
  // Firebase.functions().useFunctionsEmulator('http://localhost:5001');} */
