<template>
  <div class="h-full">
    <div class="py-2">
      <div class="container h-full">
        <!--Title-->
        <h1
          class="flex items-center font-sans font-bold break-normal text-gray-700 px-2 text-xl mt-12 lg:mt-0 md:text-2xl"
        >
          Credits
        </h1>
        <div
          class="bg-white lg:mt-4 py-4 flex sm:flex-row items-start sm:items-center justify-between px-5 sm:px-10 shadow rounded-t"
        >
          <div class="flex items-center mb-4 sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0">
            <MoonLoader
              :size="size"
              :loading="loading"
            />
            <h1
              class="text 6xl text-gray-800 text-sm font-bold"
              v-if="!loading"
            >
              {{ computedCredits }}
            </h1>
            <div class="ml-2">
              <h2 class="text-gray-800 text-sm font-bold">
                Credits availables
              </h2>
              <p class="font-bold text-xs text-gray-600">
                {{ subscription }}
              </p>
            </div>
          </div>
          <div>
            <router-link to="/dashboard/pricing">
              <a
                href="#"
                class="px-2 block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
              >Add more</a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div
      class="py-4"
      v-if="role"
    >
      <div class="container h-full">
        <div
          class="bg-white py-4 flex sm:flex-row items-start sm:items-center justify-between px-5 sm:px-10 shadow rounded-t"
        >
          <div class="flex items-center mb-4 sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0">
            <MoonLoader
              :size="size"
              :loading="loading"
            />
            <h1
              class="text 6xl text-gray-800 text-sm font-bold"
              v-if="!loading"
            >
              {{ computedcreditsUsed }}
            </h1>
            <div class="ml-2">
              <h2 class="text-gray-800 text-sm font-bold">
                Credits used this month
              </h2>
              <p class="font-bold text-xs text-gray-600">
                {{ subscriptionPeriodEnd }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Section container-->
    <section
      class="w-full lg:w-5/5"
      v-if="role"
    >
      <!--Title-->
      <h1
        class="flex items-center font-sans font-bold break-normal text-gray-700 px-2 text-xl mt-12 lg:mt-0 md:text-2xl"
      >
        Generate Screenshots
      </h1>
      <!--Card-->
      <div class="p-8 mt-6 lg:mt-4 leading-normal rounded shadow bg-white">
        <t-input-group label="Website URL:">
          <t-input
            v-model="websiteUrl"
            type="url"
          />
        </t-input-group>
        <small>https://www.economist.com/leaders/2021/01/30/the-world-is-facing-an-upsurge-of-nuclear-proliferation</small>

        <t-input-group label="Creative's URL:">
          <t-input
            v-model="creativeUrl"
            type="url"
          />
        </t-input-group>
        <small>https://cdn.bannersnack.com/banners/b1kaxq2w3/embed/index.html</small>
        <t-button
          class="mt-5"
          @click="onClick"
        >
          <MoonLoader
            :size="size"
            :loading="takingSnap"
            class="p-1"
          />
          {{ takingSnap ? "" : "Screenshot" }}
        </t-button>
      </div>
      <!--/Card-->
      <div>
        <div
          class="mb-10 p-8 mt-6 lg:leading-normal rounded shadow gradient3"
          v-if="imageUrl != ''"
        >
          <div
            class="screenshot-wrapper browser-mockup flex flex-1 m-6 md:px-0 md:m-12 bg-white rounded shadow-xl"
          >
            <img
              class="screenshot-image"
              :src="imageUrl"
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Logo from "../assets/logo.png";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import { fetchCustomer, getCustomClaimRole } from "../services/functions";

export default {
  name: "DashboardHome",
  components: { MoonLoader },
  async created() {
    /**
     * On creation fetch all the data.
     */
    const customer = await fetchCustomer(
      this.$firestore,
      this.$auth.currentUser
    );
    this.credits = customer.customerCredits;
    this.creditsUsed = customer.customerCreditsUsed;
    /**
     * If the customer is trialing a plan, sets the correct message to display.
     */
    customer.isTrialing
      ? (this.subscriptionPeriodEnd = `Your downgrade will take place on: ${customer.subscriptionPeriodEnd}`)
      : (this.subscriptionPeriodEnd = `Your current subscription ends: ${customer.subscriptionPeriodEnd}`);
    // Gets the role the customer has.
    this.role = await getCustomClaimRole(this.$auth.currentUser);
    //And sets the correct message to display
    this.subscription = this.role
      ? this.role.charAt(0).toUpperCase() + this.role.slice(1) + " " + "plan"
      : "You haven't subscribed yet";
    // Once is done, loading is set to false.
    this.isLoading();
  },
  data() {
    return {
      size: "32",
      role: null,
      logo: Logo,
      credits: NaN,
      imageUrl: "",
      loading: true,
      websiteUrl: "",
      creativeUrl: "",
      creditsUsed: NaN,
      takingSnap: false,
      subscription: null,
      subscriptionPeriodEnd: null,
    };
  },
  computed: {
    // Sets computed models to react on credits and credits used.
    computedCredits() {
      return this.credits;
    },
    computedcreditsUsed() {
      return this.creditsUsed;
    },
  },
  methods: {
    isLoading() {
      this.loading = !this.loading;
    },
    async onClick() {
      this.takingSnap = !this.takingSnap;
      const token = await this.$auth.currentUser.getIdToken();
      if (this.websiteUrl === "") return;
      const pageToScreenshot = this.websiteUrl;
      const creativeUrl = this.creativeUrl;
      const payload = { pageToScreenshot, creativeUrl };
      console.log("sent");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          "Access-Control-Allow-Origin": "*",
        },
      };
      await axios
        .post(
          //TODO: add firebase function URL,
          // "http://localhost:5001/adsnaps-2930c/us-central1/snapshot",
          payload,
          config
        )
        .then(async (res) => {
          console.log(res);
          this.imageUrl = res.data.screenshotImage;
          this.credits = (
            await fetchCustomer(this.$firestore, this.$auth.currentUser)
          ).customerCredits;
          this.creditsUsed = (
            await fetchCustomer(this.$firestore, this.$auth.currentUser)
          ).customerCreditsUsed;
          this.takingSnap = !this.takingSnap;
        })
        .catch((err) => {
          console.log(err);
          alert(err);
          this.takingSnap = !this.takingSnap;
        });
    },
  },
};
</script>
<style>
.screenshot-image {
  height: fit-content;
}
.screenshot-wrapper {
  overflow-y: auto;
}
</style>