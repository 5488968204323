<template>
  <div>
    <!-- Sidebar starts -->
    <!-- Remove class [ hidden ] and replace [ sm:block ] with [ block ] -->
    <div
      class="w-42 h-full z-20 fixed sm:relative bg-gray-800 shadow md:h-full flex-col justify-between hidden sm:flex pb-12"
    >
      <div class="px-8">
        <div class="w-full">
          <div class="flex items-center">
            <a
              class="text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
              href="/"
            >
              <img
                class="h-28 w-28"
                src="/logo.png"
              >
            </a>
          </div>
        </div>
        <ul class="mt-10 text-gray-300">
          <router-link to="/dashboard/home">
            <li
              class="flex w-full justify-between hover:text-gray-600 cursor-pointer items-center mb-6"
              id="dashboard"
            >
              <div class="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-grid"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    stroke="none"
                    d="M0 0h24v24H0z"
                  />
                  <rect
                    x="4"
                    y="4"
                    width="6"
                    height="6"
                    rx="1"
                  />
                  <rect
                    x="14"
                    y="4"
                    width="6"
                    height="6"
                    rx="1"
                  />
                  <rect
                    x="4"
                    y="14"
                    width="6"
                    height="6"
                    rx="1"
                  />
                  <rect
                    x="14"
                    y="14"
                    width="6"
                    height="6"
                    rx="1"
                  />
                </svg>
                <span class="text-sm ml-2">Dashboard</span>
              </div>
            </li>
          </router-link>
          <router-link to="/dashboard/pricing">
            <li
              class="flex w-full justify-between hover:text-gray-600 cursor-pointer items-center mb-6"
              id="pricing"
            >
              <div
                class="flex items-center"
                id="path"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-puzzle"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    stroke="none"
                    d="M0 0h24v24H0z"
                  />
                  <path
                    d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1"
                  />
                </svg>
                <span class="text-sm ml-2">Pricing</span>
              </div>
            </li>
          </router-link>
          <router-link to="/dashboard/invoices">
            <li
              class="flex w-full justify-between hover:text-gray-600 cursor-pointer items-center mb-6"
              id="invoices"
            >
              <div
                class="flex items-center"
                id="path"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-puzzle"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    stroke="none"
                    d="M0 0h24v24H0z"
                  />
                  <path
                    d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1"
                  />
                </svg>
                <span class="text-sm ml-2">Invoices</span>
              </div>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
    <!--Mobile responsive sidebar-->
    <div
      class="w-64 h-full z-20 fixed bg-gray-800 shadow md:h-full flex-col justify-between sm:hidden pb-12 transition duration-150 ease-in-out"
      id="mobile-nav"
    >
      <div
        id="openSideBar"
        class="h-10 w-10 bg-gray-800 absolute right-0 mt-16 -mr-10 flex items-center shadow rounded-tr rounded-br justify-center cursor-pointer"
        @click="sidebarHandler(true)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-adjustments"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#FFFFFF"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            stroke="none"
            d="M0 0h24v24H0z"
          />
          <circle
            cx="6"
            cy="10"
            r="2"
          />
          <line
            x1="6"
            y1="4"
            x2="6"
            y2="8"
          />
          <line
            x1="6"
            y1="12"
            x2="6"
            y2="20"
          />
          <circle
            cx="12"
            cy="16"
            r="2"
          />
          <line
            x1="12"
            y1="4"
            x2="12"
            y2="14"
          />
          <line
            x1="12"
            y1="18"
            x2="12"
            y2="20"
          />
          <circle
            cx="18"
            cy="7"
            r="2"
          />
          <line
            x1="18"
            y1="4"
            x2="18"
            y2="5"
          />
          <line
            x1="18"
            y1="9"
            x2="18"
            y2="20"
          />
        </svg>
      </div>
      <div
        id="closeSideBar"
        class="hidden h-10 w-10 bg-gray-800 absolute right-0 mt-16 -mr-10 flex items-center shadow rounded-tr rounded-br justify-center cursor-pointer text-white"
        @click="sidebarHandler(false)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-x"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            stroke="none"
            d="M0 0h24v24H0z"
          />
          <line
            x1="18"
            y1="6"
            x2="6"
            y2="18"
          />
          <line
            x1="6"
            y1="6"
            x2="18"
            y2="18"
          />
        </svg>
      </div>
      <div class="px-8">
        <div class="mt-4 flex items-center">
          <a
            class="text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
            href="/"
          >
            <img
              class="h-12"
              src="/logo-horizontal.png"
            >
          </a>
        </div>
        <ul class="mt-10 text-gray-300">
          <router-link to="/dashboard/home">
            <li
              class="flex w-full justify-between hover:text-gray-600 cursor-pointer items-center mb-6"
            >
              <div class="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-grid"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    stroke="none"
                    d="M0 0h24v24H0z"
                  />
                  <rect
                    x="4"
                    y="4"
                    width="6"
                    height="6"
                    rx="1"
                  />
                  <rect
                    x="14"
                    y="4"
                    width="6"
                    height="6"
                    rx="1"
                  />
                  <rect
                    x="4"
                    y="14"
                    width="6"
                    height="6"
                    rx="1"
                  />
                  <rect
                    x="14"
                    y="14"
                    width="6"
                    height="6"
                    rx="1"
                  />
                </svg>
                <span class="text-sm ml-2">Dashboard</span>
              </div>
            </li>
          </router-link>
          <router-link to="/dashboard/pricing">
            <li
              class="flex w-full justify-between hover:text-gray-600 cursor-pointer items-center mb-6"
            >
              <div class="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-puzzle"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    stroke="none"
                    d="M0 0h24v24H0z"
                  />
                  <path
                    d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1"
                  />
                </svg>
                <span class="text-sm ml-2">Pricing</span>
              </div>
            </li>
          </router-link>
          <router-link to="/dashboard/invoices">
            <li
              class="flex w-full justify-between hover:text-gray-600 cursor-pointer items-center mb-6"
            >
              <div class="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-puzzle"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    stroke="none"
                    d="M0 0h24v24H0z"
                  />
                  <path
                    d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1"
                  />
                </svg>
                <span class="text-sm ml-2">Invoices</span>
              </div>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
    <!-- Sidebar ends -->
  </div>
</template>

<script>
import Logo from "../assets/logo.png";

export default {
  name: "DashboardSidebar",
  data() {
    return {
      logo: Logo,
      moved: true,
    };
  },
  methods: {
    dropdownHandler(event) {
      let single = event.currentTarget.getElementsByTagName("ul")[0];
      single.classList.toggle("hidden");

      var andicator = event.currentTarget.querySelectorAll("svg");
      if (!single.classList.contains("hidden")) {
        andicator[2].style.display = "block";
        andicator[3].style.display = "none";
      } else {
        andicator[2].style.display = "none";
        andicator[3].style.display = "block";
      }
    },
    sidebarHandler() {
      var sideBar = document.getElementById("mobile-nav");
      sideBar.style.transform = "translateX(-260px)";
      if (this.$data.moved) {
        sideBar.style.transform = "translateX(0px)";
        this.$data.moved = false;
      } else {
        sideBar.style.transform = "translateX(-260px)";
        this.$data.moved = true;
      }
    },
  },
};
</script>