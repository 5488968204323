<template>
  <div class="h-screen">
    <div
      class="w-full h-full items-center justify-center"
      v-if="hasInvoices"
    >
      <ul class="flex flex-col bg-white p-4">
        <li
          class="border-gray-400 flex flex-row mb-2"
          v-for="invoice in invoices"
          :key="invoice.id"
        >
          <div
            class="select-none rounded-md flex flex-1 items-center p-4 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
          >
            <div
              class="flex flex-col rounded-md w-10 h-10 justify-center items-center mr-4"
              v-if="invoice.status === 'paid'"
            >
              <img
                src="https://img.icons8.com/ios/50/000000/renew-subscription.png"
                v-if="invoice.billing_reason === 'subscrption_cycle'"
              >
              <img
                src="https://img.icons8.com/carbon-copy/100/000000/check.png"
                v-else
              >
            </div>
            <div
              class="flex flex-col rounded-md w-10 h-10 justify-center items-center mr-4"
              v-else-if="invoice.status === 'draft'"
            >
              <img
                src="https://img.icons8.com/wired/64/000000/back-to-draft.png"
              >
            </div>
            <div
              class="flex flex-col rounded-md w-10 h-10 justify-center items-center mr-4"
              v-else-if="invoice.status === 'open'"
            >
              <img
                src="https://img.icons8.com/color/48/000000/rhombus-loader.gif"
              >
            </div>
            <div
              class="flex flex-col rounded-md w-10 h-10 justify-center items-center mr-4"
              v-else-if="invoice.status === 'void'"
            >
              <img
                src="https://img.icons8.com/cotton/64/000000/delete-sign--v1.png"
              >
            </div>
            <div class="flex-1 pl-1 mr-16">
              <div class="font-medium">
                {{ `${invoice.number}` }}
              </div>
              <div class="text-gray-600 text-sm">
                {{
                  `${
                    invoice.billing_reason.charAt(0).toUpperCase() +
                    invoice.billing_reason.slice(1).replace("_", " ")
                  }
                ${new Date(invoice.created * 1000).toDateString()}`
                }}
              </div>
            </div>
            <a
              :href="invoice.hosted_invoice_url"
              target="_blank"
            >
              <img
                src="https://img.icons8.com/pastel-glyph/24/000000/external-link.png"
              >
            </a>
            <div class="p-2" />
            <a :href="invoice.invoice_pdf">
              <img
                src="https://img.icons8.com/pastel-glyph/28/000000/download--v1.png"
              >
            </a>
          </div>
        </li>
      </ul>
    </div>
    <div
      class="w-full items-center justify-center text-gray-600 text-sm"
      v-else
    >
      <div class="select-none rounded-md bg-white flex flex-1 items-center p-4">
        <div class="font-medium">
          You don't have any invoices.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { fetchInvoices, fetchCustomer } from "../services/functions";
export default {
  name: "DashboardInvoices",
  async created() {
    this.invoices = await fetchInvoices(
      this.$firestore,
      this.$auth.currentUser.uid
    );
    this.hasInvoices = await (
        await fetchCustomer(this.$firestore, this.$auth.currentUser)
      ).hasSubscription;
  },
  data() {
    return {
      invoices: undefined,
      hasInvoices: false,
    };
  },
};
</script>
