import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import Dashboard from '@/views/Dashboard.vue'
import DashboardHome from '@/components/DashboardHome.vue'
import DashboardPricing from '@/components/DashboardPricing.vue'
import DashboardInvoices from '@/components/DashboardInvoices.vue'
import { FirebaseAuth } from '@/core/firebase'

Vue.use(VueRouter);

const routes = [
    {
        path: '*',
        redirect: '/',
    },
    {
        path: '/',
        name: 'HomePage',
        component: Home,
        meta: {
            auth: false,
        },
    },
    {
        path: '/demo',
        name: 'demo',
        component: () => import(/* webpackChunkName: 'demo' */ '@/views/Demo.vue'),
        meta: {
            auth: false,
        },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        children: [
            {
                path: 'home',
                name: 'home',
                component: DashboardHome,
            },
            {
                path: 'pricing',
                name: 'pricing',
                component: DashboardPricing,
            },
            {
                path: 'invoices',
                name: 'invoices',
                component: DashboardInvoices,
            },
        ],
        meta: {
            auth: true,
        },
        redirect: 'home'
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            auth: false,
        },
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            auth: false,
        },
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    let user = FirebaseAuth.currentUser;
    let auth = to.matched.some(record => record.meta.auth);

    if (auth && !user) {

        next('/login');

    } else if (!auth && user) {

        next('/dashboard/home');

    } else {

        next();

    }
});

export default router;
